import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Tag, Divider } from 'antd';
import { Input } from 'reactstrap';
import ScrollContainer from 'react-indiana-drag-scroll';
import ExpandedCard from '../../components/modals/ExpandedCard';
import { createApiClient, FilledBrightButton } from '@oneblinktech-org/helios-base'
import { list_menu } from '../../fakeData/svgFiles.js'
import {
    debouncedGetJobByOrg,
    debouncedGetAssignedUsers,
    debouncedGetColorList,
    debouncedGetFilterList,
    debouncedUpdateJobStatus,
    searchKeys,
    debouncedGetChooseJob,
    debouncedGetVehicleMakes,
    resetOpenJob,
    showEmptyJobsModal,
    setIsNewJob
} from '../../store/job/actions';
import { debouncedGetProfile } from '../../store/user/actions.js';
import '../../helpers/growCard.js';
import { dateFormat, formatHours, formatNumber } from '../../helpers/stringFormat.js';
import { plus } from '../../fakeData/svgFiles.js'
import TwinklingDot from '../../components/common/TwinklingDot';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import Card from '../../components/dragAndDrop/card.js';
import Filter from '../../components/modals/Filter.js';
import { getNoteList } from '../../store/note/actions.js';
import JobCardSkeleton from '../../components/JobCardSkeleton.js';
import EmptyJobsModal from '../../components/modals/EmptyJobsModal.js';
import pusher from "../../pusher";

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post } = createApiClient({ apiUrl });
const { CheckableTag } = Tag;
const EVERYONE = 0

const onDragEnd = (props, result, columns, setColumns, setIgnoreNextEvent, ignoreRef) => {
    setIgnoreNextEvent(true)
    ignoreRef.current = true;
    window.dispatchEvent(new Event('resize'));

    const { source, destination, draggableId } = result;
    if (
        !destination ||
        (destination.droppableId === source.droppableId &&
            destination.index === source.index)
    ) {
        return;
    }

    if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.jobs];
        const destItems = [...destColumn.jobs];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);

        const updatedColumns = {
            ...columns,
            [source.droppableId]: {
                ...sourceColumn,
                jobs: sourceItems
            },
            [destination.droppableId]: {
                ...destColumn,
                jobs: destItems
            }
        }
        props.debouncedUpdateJobStatus(removed.id, destColumn.id, destItems.map(a => a.id), updatedColumns)

        setColumns(updatedColumns);
    } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.jobs];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);

        const newColumns = {
            ...columns,
            [source.droppableId]: {
                ...column,
                jobs: copiedItems
            }
        }
        props.debouncedUpdateJobStatus(removed.id, column.id, copiedItems.map(a => a.id), newColumns)

        setColumns(newColumns);
    }
};

const ProductBoard = (props) => {

    const [columns, setColumns] = useState({});
    const [isCardOpen, setIsCardOpen] = useState(false);
    const [filterColor, setFilterColor] = useState([]);
    const [filterAssignedUser, setFilterAssignedUser] = useState(0);
    const [selectedJob, setSelectedJob] = useState({});
    const [loadGhostCard, setGhostCard] = useState(false);
    const [isCardChanged, setIsCardChanged] = useState(false);
    const [ignoreNextEvent, setIgnoreNextEvent] = useState(false);
    const [isFilterOpen, setFilterOpen] = useState(false)
    const [totalValue, setTotalValue] = useState(0)
    const [totalHours, setTotalHours] = useState(0)
    const [pulseAnimation, setPulseAnimation] = useState(false)
    const [archivedJob, setArchivedJob] = useState(false);
    const { planning_count, production_count } = props?.countData

    const initialRender = useRef(true);
    const location = useLocation();
    const history = useHistory();
    const ignoreRef = useRef(ignoreNextEvent);
    const params = new URLSearchParams(location.search);
    const jobId = params.get('jobId');
    const [idForTitle, setIdForTitle] = useState(jobId)
    const modalRef = React.useRef(null);

    const document_title = "Production Board"

    // New
    let args = {
        _type: 'jobs',
        _per_page: 2,
        orderby: 'rand',
        _query: {
            relation: 'AND',
            queries: [
                {
                    key: '_color',
                    value: 'color'
                },
                {
                    key: '_customer',
                    value: 'customer'
                },
                {
                    key: '_job',
                    value: 'job',
                },
            ]
        }
    };

    useEffect(() => {
        // props.getColorList()
        // props.getFilterList()

        // New
        let options = [1, 2, 3, 4, 5];

        let evenNumbers = options.filter(function (number) {
            return number % 2 === 0;
        });

        if (Object.keys(columns).length > 0) {
            const allColumns = Object.values(columns)
            let total = 0
            let hours = 0

            allColumns.forEach(column => {
                if (column.id !== 12 && column.id !== "12") {
                    column?.jobs?.forEach(el => {
                        total += Number(el?.value) || 0;
                        hours += (Number(el?.frame_hours) || 0) + (Number(el?.paint_hours) || 0);
                    });
                }
            });
            setTotalValue(total)
            setTotalHours(hours)
        }
    }, [columns]);

    // New
    const [userInfo, setUserInfo] = useState(localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) === true : true)
    const [usersToggle, setUsersToggle] = useState(localStorage.getItem('usersToggle') ? JSON.parse(localStorage.getItem('usersToggle')) === true : true)

    useEffect(() => {
        if (!Object.keys(columns).length) {
            setGhostCard(true);
        } else {
            setGhostCard(false);
            handleJobCardClick();
        }
    }, [columns, jobId]);

    const handleJobCardClick = () => {
        if (jobId) {
            if (Object.keys(columns).length > 0 && !isCardChanged) {
                let card = document.querySelector('#drag_drop-card_' + jobId);
                if (card) {
                    card.click();
                    props.setIsNewJob(Object.keys(props.newJob.data)?.length===0)
                } else {
                    let jobCard = document.querySelector('.job-item');
                    if (jobCard) {
                        jobCard.click();
                        props.debouncedGetChooseJob(jobId, true)
                        props.setIsNewJob(false)
                    }
                }
                setIsCardChanged(false);
            }
        }
    };

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            setGhostCard(false);
            setColumns(props.jobStatuses.data)
        }
        return () => {

            isMounted = false
        }
    }, [props.jobStatuses.data])

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            setColumns(props.jobStatuses.data)
        }
        return () => {
            isMounted = false
        }
    }, [props.jobStatuses.errors])

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            if (props.newJob.errors && Object.keys(props.newJob.errors)?.length > 0) {
                modalRef.current?.click()
            }
        }
        return () => {
            isMounted = false
        }
    }, [props.newJob.errors])

    useEffect(() => {
        let isMounted = true;
        props.debouncedGetJobByOrg()
        props.getCurrentUser()
        props.debouncedGetColorList()
        props.debouncedGetFilterList()
        props.debouncedGetVehicleMakes()

        return () => { isMounted = false }
    }, []);

    useEffect(() => {
        props.debouncedGetAssignedUsers()
        if (props.assignedUsers.data.length > 0) {
            let selectedAUser = localStorage.getItem('assignedUser') ? localStorage.getItem('assignedUser') : 0
            setFilterAssignedUser(selectedAUser)
        }
    }, [])

    useEffect(() => {
        const channelProduct = pusher.subscribe("product-board");
        const handleJobEvent = (data) => {
            if (ignoreRef.current) {
                setIgnoreNextEvent(false);
                return;
            }

            if (data.event === "card changed") {
                props.debouncedGetJobByOrg();
                if (data.value != null) {
                    setIsCardChanged(true);
                }
                if (data?.value && selectedJob) {
                    props.getNoteList(data.value);
                }
            }
        };
        channelProduct.bind("job-event", handleJobEvent);

        const channelOrganizations = pusher.subscribe("organizations");
        const handleChooseEvent = (data) => {
            if (initialRender.current) {
                initialRender.current = false;
                return;
            }
            props.debouncedGetJobByOrg();
            pusher.unsubscribe("organizations");
        };
        channelOrganizations.bind("choose-event", handleChooseEvent);

        const channelPlanning = pusher.subscribe("planning-board");
        const handleMoveEvent = () => {
            props.debouncedGetJobByOrg();
        };
        channelPlanning.bind("move-event", handleMoveEvent);

        return () => {
            channelProduct.unbind("job-event", handleJobEvent);
            channelOrganizations.unbind("choose-event", handleChooseEvent);
            channelPlanning.unbind("move-event", handleMoveEvent);

            pusher.unsubscribe("product-board");
            pusher.unsubscribe("organizations");
            pusher.unsubscribe("planning-board");
        };
    }, [props.debouncedGetJobByOrg, props.getNoteList, ignoreRef, initialRender]);

    useEffect(() => {
        ignoreRef.current = ignoreNextEvent;
    }, [ignoreNextEvent]);

    useEffect(() => {
        const openModal = async () => {
            const isShow = await localStorage.getItem("doNotShowEmptyJobsModal") 
            if (planning_count === 0 && production_count === 0 && isShow !== true && isShow !== 'true') {
                await props.showEmptyJobsModal()
            }
        }
        openModal()
    },[planning_count, production_count])

    const ghostCard = () => {
        if (loadGhostCard) {
            return [...Array(5)].map((e, i) => {
                return (
                    <div
                        className="d-flex flex-column align-items-center ghost-card"
                        style={{ borderRight: "1px solid rgba(215, 223, 235, 0.8)", background: "#fff" }}
                        key={i}
                    >
                        <div className="d-flex justify-content-between w-100">
                            <span className="skeleton-item"><span>0000000</span></span>
                            <span className="skeleton-item">
                                <small>00</small>
                            </span>
                        </div>
                        <div className="w-100">
                            <div className="drag_drop-card mt-2 d-flex flex-column">
                                <div className="d-flex justify-content-between">
                                    <span className="skeleton-item"><span>#0000</span></span>
                                    <small className="skeleton-item"><span>00-00-00</span></small>
                                </div>
                                <span className="skeleton-item"><span>0000</span></span>
                                <span className="skeleton-item"><span>0000</span></span>
                                <div className="skeleton-item"><span>0000</span></div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    useEffect(() => {
        if (Object.keys(props.newJob.data)?.length > 0) {
            changeBackground({ "id": props?.newJob?.data?.color_id, "color": props?.newJob?.data?.color })
        }
    },[props.newJob.data])

    const changeBackground = (color) => {
        setSelectedJob({ ...selectedJob, color_id: color?.id, job_color: color })
    }

    const onChooseAssignedUser = (e) => {
        setFilterAssignedUser(e.target.value)
        localStorage.setItem('assignedUser', e.target.value)
    }

    const displayShortName = (user) => {
        if (user) {
            return (user.firstname == null ? '' : user.firstname.charAt(0)) + (user.lastname == null ? '' : user.lastname.charAt(0));
        }

        return '';
    }

    const shortNameBackground = (date) => {
        var datum = ((Date.parse(date)) / 1000).toString();
        return '#' + datum.substring(datum.length - 6)
    }

    const [selectedOption, setSelectedOption] = useState('');

    const handleSearchChange = async (e) => {
        setSelectedOption(e.target.value);

        try {
            const token = localStorage.getItem('h-access_token')

            post('/jobs/productionFilter', { filter: e.target.value }, {
                "Authorization": `Bearer ` + token
            }).then(result => {
                if (result.status == 200) {
                    if (result.data.length > 0) {
                        setColumns({ ...result.data })
                    }
                }
            }).catch(e => {
                return e
            })
        } catch (error) {
            console.error("Error sending data:", error);
        }
    }

    const generateTitle = (id) => {
        return document_title + (id ? " - Job #" + id : "");
    };

    const onArchiveJob = (status) => {
        // status - 0: archive, 1: unarchive
        if(status == 0) {
            setArchivedJob(true)
        }else if(status == 1) {
            setPulseAnimation(false)
            setArchivedJob(false)
            setTimeout(() => {
                setPulseAnimation(false)
            }, 5000)
        }
    }

    useDocumentTitle(generateTitle(idForTitle));

    return (
        <div className="production-board">
            <div className="header pb-3">
                <h5 className="title">Production Board</h5>
                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center calculation">
                    <div className="filter_wrap">
                        <label className="mb-0 mr-4">Total Value in Production:</label>
                        <label>{formatNumber(totalValue, 2, '$')}</label>
                    </div>
                    <Divider type='vertical'
                        style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.8)', borderColor: 'rgba(0, 0, 0, 0.2)' }}
                        className="d-flex"
                    />
                    <div className="filter_wrap">
                        <label className="mb-0 mr-4">Total Hours in Production:</label>
                        <label>{formatHours(totalHours, 'h')}</label>
                    </div>
                </div>
                <div className="action">
                    <div id={`drag_drop-card_0`} className='job-item' >
                        <FilledBrightButton
                            svgFile={plus}
                            title="New Job"
                            clickEvent={(e) => props.setIsNewJob(true) }
                            className="mr-2"
                        />
                    </div>
                    <div className='pointer' onClick={() => setFilterOpen(!isFilterOpen)} style={{backgroundColor: isFilterOpen ? '#5D6F88' : ''}}>
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.667 2.1c0-1.16-.896-2.1-2-2.1-.871 0-1.612.584-1.886 1.4H.689a.8.8 0 0 0-.273.048.62.62 0 0 0-.368.37.8.8 0 0 0-.048.274q0 .16.048.282.048.12.144.233a.6.6 0 0 0 .224.145.8.8 0 0 0 .273.048H4.78c.274.816 1.015 1.4 1.886 1.4 1.104 0 2-.94 2-2.1m0 0q-.001-.369-.114-.7h6.758q.144 0 .273.048a.62.62 0 0 1 .368.37.8.8 0 0 1 .048.274.8.8 0 0 1-.048.282.8.8 0 0 1-.144.233.6.6 0 0 1-.224.145.8.8 0 0 1-.273.048H8.553q.113-.33.114-.7M.192 7.507a.8.8 0 0 1-.144-.233A.8.8 0 0 1 0 6.992q0-.144.048-.273a.63.63 0 0 1 .368-.37A.8.8 0 0 1 .69 6.3h9.425c.274-.815 1.015-1.4 1.886-1.4.87 0 1.612.585 1.886 1.4h1.425q.144 0 .273.049a.62.62 0 0 1 .368.37.8.8 0 0 1 .048.273.8.8 0 0 1-.048.282.8.8 0 0 1-.144.233.6.6 0 0 1-.224.145.8.8 0 0 1-.273.048h-1.425q.093-.272.11-.575C13.936 8.227 13.066 9.1 12 9.1s-1.935-.874-1.997-1.976q.019.303.11.576H.69a.8.8 0 0 1-.273-.048.6.6 0 0 1-.224-.145m-.144 4.667a.8.8 0 0 0 .144.233.6.6 0 0 0 .224.145.8.8 0 0 0 .273.048h1.425A2.2 2.2 0 0 1 2 11.946C2.024 13.085 2.91 14 4 14s1.976-.915 2-2.054a2.2 2.2 0 0 1-.114.654h9.425a.8.8 0 0 0 .273-.048.6.6 0 0 0 .224-.145.8.8 0 0 0 .144-.233.8.8 0 0 0 .048-.282.8.8 0 0 0-.048-.274.63.63 0 0 0-.368-.37.8.8 0 0 0-.273-.048H5.886C5.612 10.384 4.871 9.8 4 9.8c-.87 0-1.612.584-1.886 1.4H.689a.8.8 0 0 0-.273.048.62.62 0 0 0-.368.37.8.8 0 0 0-.048.274q0 .16.048.282" fill={`${isFilterOpen ? '#ffffff': '#5D6F88'}`}/></svg>
                    </div>
                </div>
            </div>
            <Filter open={isFilterOpen} setFilterOpen={setFilterOpen} colorList={props.colorList.data} setFilterColor={setFilterColor} jobStatuses={props.jobStatuses} />
            <ScrollContainer
                className="d-flex flex-row justify-content-between drag_drop production-board-table production-board_content"
                ignoreElements=".drag_drop-card, *[prevent-drag-scroll]"
                hideScrollbars={false}
            >
                {
                    ghostCard()
                }
                <DragDropContext
                            onDragEnd={result => onDragEnd(props, result, columns, setColumns, setIgnoreNextEvent, ignoreRef)}
                        >
                            {Object.keys(columns).length > 0 && Object.entries(columns).map(([columnId, column], index) => {
                                const value = column?.jobs?.reduce((accumulator, currentValue) => accumulator + Number(currentValue?.value), 0)
                                const hours = column?.jobs?.reduce((accumulator, currentValue) => accumulator + (Number(currentValue?.frame_hours) + Number(currentValue?.paint_hours)), 0)
                                return (
                                    <div
                                        className="d-flex flex-column align-items-center job-main-column"
                                        key={columnId.toString()}
                                    >
                                        <div style={{ userSelect: "none" }} className=" column-title-wrap">
                                            <div className='column-title-content'>
                                                <div className='d-flex justify-content-between w-100'>
                                                    <p className="m-0 column-title">{column.name}</p>
                                                    <small className='column-title'>{column.jobs.length}</small>
                                                </div>
                                                <div className='d-flex justify-content-between w-100'>
                                                    <span className="m-0 column-title-sub">Total Value</span>
                                                    <span className='column-title-sub'>{formatNumber(value, 2, '$')}</span>
                                                </div>
                                                <div className='d-flex justify-content-between w-100'>
                                                    <span className="m-0 column-title-sub">Total Hours</span>
                                                    <span className='column-title-sub'>{formatHours(hours, 'h')}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-100 h-height job-inner-column">
                                            {planning_count === 0 && production_count === 0 ?
                                                <JobCardSkeleton />
                                                :
                                                <Droppable droppableId={columnId.toString()} key={columnId.toString()}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                                isDraggingOver={snapshot.isDraggingOver}
                                                                className="drag_drop-panel"
                                                                style={{ backgroundColor: snapshot.isDraggingOver ? "#f6faff" : "inherit", border: snapshot.isDraggingOver ? "1px solid #d7dbda" : "inherit" }}
                                                            >
                                                                {column.jobs.map((item, index) => {
                                                                    return (
                                                                        <Draggable
                                                                            key={item.id.toString()}
                                                                            draggableId={item.id.toString()}
                                                                            index={index}
                                                                        >
                                                                            {(provided, snapshot) => {
                                                                                return (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        id={`drag_drop-card_${item.id}`}
                                                                                        className={(((filterColor.length > 0 && !filterColor.includes(props.colorList.data.find(data => { return data.id == item.color_id }) != undefined ? props.colorList.data.find(data => { return data.id == item.color_id })['color'] : '')) || (filterAssignedUser != EVERYONE && filterAssignedUser != item.assigned_to_user_id)) ? 'filter_hidden' : '') + " drag_drop-card"}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault()
                                                                                            setSelectedJob(item)
                                                                                            props.setIsNewJob(false)
                                                                                            setIdForTitle(item.id)
                                                                                            props.debouncedGetChooseJob(item.id, true)
                                                                                            const queryParams = '?jobId=' + item.id;
                                                                                            window.history.pushState({}, '', `#${history.location.pathname}${queryParams}`);
                                                                                        }}
                                                                                    >
                                                                                        <Card data={item} colorList={props.colorList} />
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        );
                                                    }}
                                                </Droppable>
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                </DragDropContext>

            </ScrollContainer>
            <Filter open={isFilterOpen} setFilterOpen={setFilterOpen} colorList={props.colorList.data} setFilterColor={setFilterColor} />

            {planning_count === 0 && production_count === 0 && !props.isNewJob &&
                <EmptyJobsModal />
            }
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        jobStatuses: state.JobReducer.jobStatuses,
        colorList: state.JobReducer.colorList,
        assignedUsers: state.JobReducer.assignedUsers,
        filterOptions: state.JobReducer.filterOptions,
        user: state.UserReducer.user,
        newJob: state.JobReducer.newJob,
        isNewJob: state.JobReducer.isNewJob,
        countData: state.NoteReducer.countData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        debouncedGetJobByOrg: () => debouncedGetJobByOrg(dispatch),
        debouncedGetAssignedUsers: () => debouncedGetAssignedUsers(dispatch),
        getCurrentUser: () => debouncedGetProfile(dispatch),
        debouncedGetColorList: () => debouncedGetColorList(dispatch),
        debouncedGetFilterList: () => debouncedGetFilterList(dispatch),
        debouncedUpdateJobStatus: (jobId, statusId, itemOrders, columns) => debouncedUpdateJobStatus(dispatch, jobId, statusId, itemOrders, columns),
        searchKeys: (key) => dispatch(searchKeys(key)),
        debouncedGetChooseJob: (id, loading) => debouncedGetChooseJob(dispatch, id, loading),
        debouncedGetVehicleMakes: () => debouncedGetVehicleMakes(dispatch),
        resetOpenJob: () => dispatch(resetOpenJob()),
        getNoteList: (jobId) => dispatch(getNoteList(jobId)),
        showEmptyJobsModal:()=> dispatch(showEmptyJobsModal()),
        setIsNewJob: (isNew) =>  dispatch(setIsNewJob(isNew))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBoard);
