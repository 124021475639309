import Pusher from 'pusher-js';

if (process.env.NODE_ENV !== 'production') {
    Pusher.logToConsole = true;
}

const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_API_KEY}`, {
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
});

export default pusher;